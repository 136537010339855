<template>
  <b-sidebar
    id="add-new-contact-sidebar"
    :visible="isShowGuideSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-show-guide-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Guía
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <div class="p-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <!-- Nro guide -->
            <b-form-group
              label="Nro Guia"
              label-for="nro_guide"
            >
              <b-form-input
                id="nro_guide"
                :value="guide.nro_guide"
                plaintext
              />
            </b-form-group>

          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <!-- Reference Guide -->
            <b-form-group
              label="Ref. de venta"
              label-for="ref_sale"
            >
              <b-form-input
                id="ref_sale"
                :value="guide.sale_code"
                plaintext
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <!-- Nro invoice -->
            <b-form-group
              label="Nro factura"
              label-for="nro_invoice"
            >
              <b-form-input
                id="nro_invoice"
                :value="guide.nro_invoice"
                plaintext
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <!-- Date issue -->
            <b-form-group
              label="Fecha emisión"
              label-for="date_issue"
            >
              <b-form-input
                id="date_issue"
                :value="guide.created_at"
                plaintext
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="12"
          >
            <!-- Start point -->
            <b-form-group
              label="Punto partida"
              label-for="start_point"
            >
              <b-form-input
                id="start_point"
                :value="guide.warehouse_address"
                plaintext
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="12"
          >
            <!-- Arrival point -->
            <b-form-group
              label="Punto llegada"
              label-for="arrival_point"
            >
              <b-form-input
                id="arrival_point"
                :value="guide.arrivalPoint"
                plaintext
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="12"
          >
            <!-- Registration number -->
            <b-form-group
              label="Placa"
              label-for="registration_number"
            >
              <b-form-input
                id="registration_number"
                :value="guide.registrationNumber"
                plaintext
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="12"
          >
            <!-- Driver -->
            <b-form-group
              label="Conductor-licencia"
              label-for="driver_license"
            >
              <b-form-input
                id="driver_license"
                class="text-wrap"
                :value="guide.driver"
                plaintext
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="12"
          >
            <!-- Seller -->
            <b-form-group
              label="Vendedor"
              label-for="seller"
            >
              <b-form-input
                id="seller"
                class="text-wrap"
                :value="guide.seller"
                plaintext
              />
            </b-form-group>
          </b-col>
        </b-row>
      </div>

    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BFormGroup, BFormInput, BRow, BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BSidebar,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isShowGuideSidebarActive',
    event: 'update:is-show-guide-sidebar-active',
  },
  props: {
    isShowGuideSidebarActive: {
      type: Boolean,
      required: true,
    },
    guide: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
}
</script>
