import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchGuides(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('admin/guides', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteGuide(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`admin/guide/${id}/delete`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createGuide(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`admin/guide/${id}/create`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editGuide(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`admin/guide/${id}/edit`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addGuide(ctx, { body }) {
      return new Promise((resolve, reject) => {
        axios
          .post('admin/guide/store', body)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
