<template>
  <b-card
    no-body
  >
    <show-guide-business-sidebar
      :business="businessData"
      :is-show-guide-business-sidebar-active.sync="isShowGuideBusinessSidebarActive"
    />

    <show-guide-sidebar
      :guide="guideData"
      :is-show-guide-sidebar-active.sync="isShowGuideSidebarActive"
    />

    <div class="m-2">
      <b-row>
        <b-col
          cols="12"
          md="7"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <div class="">
            <h3>Total de guias</h3> <small> (Hay {{ totalGuides }} guias)</small>
          </div>
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="5"
        >
          <div class="d-flex align-items-center justify-content-end  mb-1 mb-md-0">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Buscar por N° de guía,cuenta o N° de factura"
            />
          </div>
        </b-col>
      </b-row>

    </div>

    <b-table
      ref="refGuidesListTable"
      :items="fetchGuides"
      responsive
      :fields="tableColumns"
      primary-key="id"
      show-empty
      empty-text="No se encontraron registros coincidentes"
      class="position-relative"
    >
      <!-- Column: Id -->

      <!-- Column: Nro Guide -->
      <template #cell(nro_guide)="data">
        <b-link
          class="font-weight-bold d-block text-nowrap"
          @click="showGuideSidebar(data.item)"
        >
          {{ data.item.nro_guide }}
        </b-link>
      </template>

      <!-- Column: Business name -->
      <template #cell(business_name)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              size="32"
              :text="avatarText(data.item.business_name)"
              variant="light-primary"
            />
          </template>
          <!--          <span class="font-weight-bold d-block text-nowrap">-->
          <!--            {{ data.item.business_name }}-->
          <!--          </span>-->
          <b-link
            class="font-weight-bold d-block text-nowrap"
            @click="showBusinessSidebar(data.item)"
          >
            {{ data.item.business_name }}
          </b-link>
          <small class="text-muted">{{ data.item.client }}</small>
        </b-media>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">

        <div class="text-nowrap">
          <feather-icon
            :id="`invoice-row-${data.item.id}-edit-icon`"
            icon="EditIcon"
            class="cursor-pointer"
            size="16"
            @click="$router.push({ name: 'guides-edit', params: { id: data.item.guide_id }})"
          />
          <b-tooltip
            title="Editar"
            class="cursor-pointer"
            :target="`invoice-row-${data.item.id}-edit-icon`"
          />

          <feather-icon
            :id="`invoice-row-${data.item.id}-print-icon`"
            icon="PrinterIcon"
            size="16"
            class="mx-1 cursor-pointer"
            @click="openPDF(data.item)"
          />
          <b-tooltip
            title="Imprimir guía"
            :target="`invoice-row-${data.item.id}-print-icon`"
          />

          <feather-icon
            :id="`guide-row-${data.item.id}-delete-icon`"
            icon="TrashIcon"
            size="16"
            class="cursor-pointer"
          />
          <b-tooltip
            title="Eliminar"
            :target="`guide-row-${data.item.id}-delete-icon`"
          />
          <b-popover
            :target="`guide-row-${data.item.id}-delete-icon`"
            variant="danger"
            triggers="focus"
            placement="left"
          >
            <template #title>
              <span>Eliminar guía</span>
            </template>

            <div class="">
              <p>
                ¿Estás seguro que desea eliminar la guía <b>{{ data.item.nro_guide }}</b>?
              </p>
            </div>

            <b-button
              variant="outline-danger"
              class="mr-1"
              size="sm"
              @click="deleteGuide(data.item)"
            >
              <span class="text-nowrap">Eliminar</span>
            </b-button>
          </b-popover>
        </div>
      </template>

    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="12"
          class="d-flex align-items-center justify-content-end justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalGuides"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BAvatar,
  BCard,
  BCol,
  BFormInput,
  BMedia, BPagination,
  BRow,
  BTable, BTooltip, BLink, BPopover,BButton,
} from 'bootstrap-vue'
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import guideHandler from '@/views/dramox/guides/guideHandler'
import guideStoreModule from '@/views/dramox/guides/guideStoreModule'
import axios from '@axios'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import ShowGuideBusinessSidebar from '@/views/dramox/guides/ShowGuideBusinessSidebar'
import ShowGuideSidebar from '@/views/dramox/guides/ShowGuideSidebar'

export default {
  name: 'HomeBusiness',
  components: {
    ShowGuideSidebar,
    ShowGuideBusinessSidebar,
    BCard,
    BRow,
    BCol,
    BFormInput,
    // BButton,
    BTable,
    BMedia,
    BAvatar,
    BPagination,
    BTooltip,
    BLink,
    BPopover,
    BButton,
  },
  data() {
    return {
    }
  },
  setup() {
    const BUSINESS_APP_STORE_MODULE_NAME = 'app-guides'

    // Register module
    if (!store.hasModule(BUSINESS_APP_STORE_MODULE_NAME)) store.registerModule(BUSINESS_APP_STORE_MODULE_NAME, guideStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(BUSINESS_APP_STORE_MODULE_NAME)) store.unregisterModule(BUSINESS_APP_STORE_MODULE_NAME)
    })

    const isShowGuideBusinessSidebarActive = ref(false)
    const isShowGuideSidebarActive = ref(false)
    const businessData = ref({})
    const guideData = ref({})

    const toast = useToast()

    const showNotification = ({
      title,
      icon,
      variant,
    }) => {
      toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant,
        },
      })
    }

    const {
      tableColumns,
      currentPage,
      totalGuides,
      perPage,
      searchQuery,
      refGuidesListTable,
      refetchData,
      fetchGuides,
    } = guideHandler()

    const openPDF = val => {
      const url = `${axios.defaults.baseURL}admin/print/guide/${val.guide_id}`
      window.open(url, '_blanck')
    }

    const deleteGuide = item => {
      store.dispatch('app-guides/deleteGuide', { id: item.guide_id })
        .then(() => {
          refetchData()
          showNotification({ title: 'Eliminado con éxito', icon: 'CheckCircleIcon', variant: 'success' })
        })
        .catch(() => {
          showNotification({ title: 'Oops!, ocurrió un error', icon: 'AlertTriangleIcon', variant: 'danger' })
        })
    }

    const showBusinessSidebar = item => {
      isShowGuideBusinessSidebarActive.value = true
      businessData.value = item
    }

    const showGuideSidebar = item => {
      isShowGuideSidebarActive.value = true
      guideData.value = item
    }

    return {
      deleteGuide,

      fetchGuides,
      tableColumns,
      currentPage,
      totalGuides,
      searchQuery,
      perPage,
      refGuidesListTable,
      refetchData,
      avatarText,

      openPDF,

      isShowGuideBusinessSidebarActive,
      isShowGuideSidebarActive,
      showBusinessSidebar,
      showGuideSidebar,
      businessData,
      guideData,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
